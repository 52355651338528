import { Environment } from '@interfaces/Environment';

export const environment: Environment = {
  production: true,
  useAuth: true,
  useMocks: false,
  mockDelay: 0,
  backendBaseUrl:
    'https://backend-api-uthyrningslagen-staging.azurewebsites.net',
};
